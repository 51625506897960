export const useScrollLock = () => {
    const disableScroll = () => {
        document.body.style.overflow = 'hidden';
    };

    const enableScroll = () => {
        document.body.style.overflow = '';
    };

    return { disableScroll, enableScroll };
}
