<script setup lang="ts">
    const preferredLanguage = usePreferredLanguage();
    const { globalAssets } = useGlobalAssets();
    const route = useRoute();

    const refreshState = () => {
        if (route.path === '/') {
            preferredLanguage.value = [];
            globalAssets.value = [];
            window.location.reload();
        }
}
</script>

<template>
    <header class="h-[80px] w-full flex-shrink-0 border-b-2">
        <div class="flex items-center justify-between gap-2 mx-auto px-4 py-6 sm:px-6 lg:px-8">
            <NuxtLink
                to="/"
                @click="refreshState"
            >
                <h1 class="text-[1.2rem] font-bold">Flügger Document Manager</h1>
            </NuxtLink>
            <SearchBox />

            <div class="flex items-center justify-between gap-5">
                <LanguageSwitcher />
                <DownloadButton />
            </div>
        </div>
    </header>
</template>
