<script setup>
    import {
        Command,
        CommandEmpty,
        CommandGroup,
        CommandInput,
        CommandItem,
        CommandList,
    } from '@/components/ui/command';

    import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

    import { cn } from '@/lib/utils';
    import { CaretSortIcon, CheckIcon } from '@radix-icons/vue';

    import { languages } from '~/labels';

    const open = ref(false);
    const preferredLanguage = usePreferredLanguage();
    
    const languagesList = computed(() =>
        Object.entries(languages).map(([value, label]) => ({ value, label }))
    );

    function handleSelect(code) {
        preferredLanguage.value = [code];
        open.value = false;
    }

    function handleSelectAll() {
        preferredLanguage.value = [];
        open.value = false;
    }
</script>

<template>
    <Popover v-model:open="open">
        <PopoverTrigger as-child>
            <Button
                variant="outline"
                role="combobox"
                :aria-expanded="open"
                class="w-[180px] justify-between"
            >
                {{
                    preferredLanguage.length > 0
                        ? languages[preferredLanguage[0]]
                        : 'Select language'
                }}
                <CaretSortIcon class="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
        </PopoverTrigger>
        <PopoverContent class="w-[180px] p-0">
            <Command>
                <CommandInput
                    class="h-9"
                    placeholder="Search language..."
                />
                <CommandEmpty>No language found.</CommandEmpty>
                <CommandList>
                    <CommandGroup>
                        <!-- "Show all languages" option -->
                        <CommandItem
                            :key="'all-languages'"
                            :value="null"
                            @select="handleSelectAll"
                        >
                            Show all languages
                            <CheckIcon
                                :class="
                                    cn(
                                        'ml-auto h-4 w-4',
                                        preferredLanguage.length === 0
                                            ? 'opacity-100'
                                            : 'opacity-0'
                                    )
                                "
                            />
                        </CommandItem>

                        <!-- Language options -->
                        <CommandItem
                            v-for="language in languagesList"
                            :key="language.value"
                            :value="language.label"
                            @select="handleSelect(language.value)"
                        >
                            {{ language.label }}
                            <CheckIcon
                                :class="
                                    cn(
                                        'ml-auto h-4 w-4',
                                        preferredLanguage[0] === language.value
                                            ? 'opacity-100'
                                            : 'opacity-0'
                                    )
                                "
                            />
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
