<script setup lang="ts">
    import SearchBox from 'vue-instantsearch/vue3/es/src/components/SearchBox.vue';
    import { debounce } from 'perfect-debounce';

    const searchQuery = useState<string>('searchQuery', () => '');
    const searchInputRef = ref<HTMLInputElement | null>(null);
    const refineFuncRef = ref<CallableFunction>(null);

    const focusSearchInput = () => {
        searchInputRef.value?.focus();
    };

    const handleSearchShortcut = (event: KeyboardEvent) => {
        if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            searchInputRef.value?.focus();
        }
    };

    function searchHandler(currentRefinement: string, refineCallback: CallableFunction) {
        if (searchQuery.value.length >= 3) {
            refineCallback(searchQuery.value);
            return;
        }

        if (currentRefinement) {
            refineCallback('');
        }

        return;
    }

    const debouncedSearchHandler = debounce(searchHandler, 100, {
        leading: false,
        trailing: true,
    });

    const testVNode = (refine: CallableFunction) => {
        refineFuncRef.value = refine;
    };

    watch(searchQuery, () => {
        refineFuncRef.value(searchQuery.value);
    });

    onMounted(() => {
        window.addEventListener('keydown', handleSearchShortcut);
    });

    onUnmounted(() => {
        window.removeEventListener('keydown', handleSearchShortcut);
    });
</script>

<template>
    <SearchBox class="md:w-1/3">
        <template v-slot="{ currentRefinement, isSearchStalled, refine }">
            <div
                @click="focusSearchInput"
                class="text-md flex w-full cursor-text items-center gap-1 rounded-lg border border-gray-300 bg-gray-50 p-1.5 text-gray-900 focus-within:ring-[1px] focus-within:ring-blue-500 sm:text-sm"
            >
                <label
                    for="search"
                    class="sr-only"
                >
                    Search products
                </label>
                <svg
                    data-slot="icon"
                    fill="none"
                    stroke-width="1.5"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    class="h-4 w-4 cursor-auto"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    ></path>
                </svg>
                <input
                    type="text"
                    id="search"
                    ref="searchInputRef"
                    v-model="searchQuery"
                    @input="debouncedSearchHandler(currentRefinement, refine)"
                    @vue:mounted="testVNode(refine)"
                    class="w-full bg-gray-50 focus:outline-none"
                    placeholder="Search products..."
                    aria-label="Search products"
                />
            </div>
        </template>
    </SearchBox>
</template>
