<script setup lang="ts">
    import JSZip from 'jszip';

    const { globalAssets, assetsDownloadPath, assetsPreviewPath } = useGlobalAssets();
    const { logEvent } = useMetrics();

    const { disableScroll, enableScroll } = useScrollLock();

    const isCartOpen = useState<boolean>('isCartOpen');

    const downloadAll = () => {
        const check = confirm(
            `Are you sure you want to download the ${globalAssets.value.length} assets all at once?`
        );

        if (check) {
            globalAssets.value.forEach(asset => {
                const link = document.createElement('a');
                link.href = assetsDownloadPath + asset.id;
                link.download = asset.name;
                link.click();
            });

            logEvent('download_all_as_single', 'finish', {
                assets_count: String(globalAssets.value.length),
            });
        } else {
            logEvent('download_all_as_single', 'abort', {
                assets_count: String(globalAssets.value.length),
            });
        }
    };

    const isDownloading = ref(false);
    const progress = ref(0);
    const downloadZip = async () => {
        progress.value = 0;
        isDownloading.value = true;
        const startTime = new Date().getTime();

        const zip = new JSZip();
        const promises = globalAssets.value.map(async ({ id, name, updated_at }) => {
            const url = assetsDownloadPath + id;
            console.log('Fetching: ' + name, url);
            const pdf = await fetch(url);
            const blob = await pdf.arrayBuffer();
            zip.file(name, blob, {
                date: new Date(updated_at),
            });
            progress.value += 100 / globalAssets.value.length;
        });
        await Promise.all(promises);

        // Return the zip file as a response.
        const blob = await zip.generateAsync({ type: 'blob' });

        const duration = new Date().getTime() - startTime; // ms
        logEvent('download_all_as_zip', null, {
            assets_count: String(globalAssets.value.length),
            duration_ms: String(duration),
        });

        // make and click a temporary link to download the Blob
        const link = document.createElement('a');
        const today = new Date().toDateString();
        const filename = `flugger-assets-${today}.zip`;
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        link.remove();

        isDownloading.value = false;
        progress.value = 0;
        alert(`Check your download folder for the file called ${filename}`);
    };

    const deleteAsset = (assetId: string) => {
        const index = globalAssets.value.findIndex(asset => asset.id === assetId);
        if (index !== -1) {
            globalAssets.value.splice(index, 1);
        }

        logEvent('delete_asset', null, {
            asset_id: assetId,
        });
    };

    const emptyCart = () => {
        globalAssets.value = [];
        logEvent('empty_cart', null, {
            assets_count: String(globalAssets.value.length),
        });
    };

    const closeCart = () => {
        isCartOpen.value = false;
    };

    watchEffect(() => {
        if (isCartOpen.value) {
            disableScroll();
        } else {
            enableScroll();
        }
    });
</script>
<template>
    <aside>
        <Transition name="cart-fade">
            <div
                v-show="isCartOpen"
                class="fixed inset-0 z-50 h-full w-full bg-black/30 backdrop-blur-sm"
                @click="closeCart"
            >
                <Transition name="cart-slide">
                    <div
                        v-show="isCartOpen"
                        class="fixed end-0 flex h-full w-full flex-col items-center justify-start bg-white/90 md:w-1/3"
                        @click.stop
                    >
                        <div class="flex h-full min-w-full flex-col px-8 py-6">
                            <div class="flex items-center justify-between">
                                <span class="text-xl font-bold">Your Queue</span>
                                <button
                                    type="button"
                                    @click="closeCart"
                                    class="btn btn-outline btn-sm border-gray-300 font-normal"
                                    aria-label="Close Cart and Return to Search"
                                >
                                    <!-- <span class="sr-only">Close Cart</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="h-6 w-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M6 18 18 6M6 6l12 12"
                                        />
                                    </svg> -->
                                    Back to Search
                                </button>
                            </div>
                            <template v-if="!globalAssets.length">
                                <p class="mt-20 font-bold text-gray-500">
                                    No documents selected
                                </p>
                            </template>
                            <template v-else>
                                <p class="mt-20 font-bold text-gray-500">
                                    You have selected
                                    <span class="font-bold">
                                        {{ globalAssets.length }}
                                    </span>
                                    documents
                                </p>
                                <div class="mt-6 flex h-3/5 flex-col gap-2 overflow-auto">
                                    <!--  <div
                                        :key="asset.name"
                                        v-for="asset in globalAssets"
                                        class="group relative justify-start"
                                    > -->
                                    <!-- <div
                                            class="grid grid-cols-2 gap-2 text-sm text-gray-500 hover:text-red-500"
                                        >
                                            <span>
                                                {{ asset.name }}
                                            </span>
                                            <span class="text-right">
                                                Updated on
                                                {{
                                                    new Date(
                                                        asset.updated_at
                                                    ).toLocaleDateString()
                                                }}
                                            </span>
                                        </div>
                                        <button
                                            type="button"
                                            @click="deleteAsset(asset.id)"
                                            class="absolute right-[180px] top-1/2 hidden -translate-y-1/2 transform group-hover:block"
                                        >
                                            <span class="sr-only">Delete Asset</span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="h-4 w-4 text-red-500"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M6 18 18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>

                                        

                                        <!--  
                                        <img
                                            :src="assetsPreviewPath + asset.id"
                                            alt="Asset preview"
                                            class="absolute right-0 top-0 hidden h-auto w-64 group-hover:block"
                                            loading="lazy"
                                        /> -->

                                    <!-- <div
                                            class="grid grid-cols-[1fr,auto,auto] gap-2 text-sm text-gray-500 hover:text-red-500"
                                        >
                                            <span>
                                                {{ asset.name }}
                                            </span>
                                            <span class="text-right">
                                                Updated on
                                                {{
                                                    new Date(
                                                        asset.updated_at
                                                    ).toLocaleDateString()
                                                }}
                                            </span>
                                            <button
                                                type="button"
                                                @click="deleteAsset(asset.id)"
                                                class="hidden group-hover:block"
                                            >
                                                <span class="sr-only">Delete Asset</span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="h-4 w-4 text-red-500"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M6 18 18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div> -->
                                    
                                    <div
                                        :key="asset.name"
                                        v-for="asset in globalAssets"
                                        class="group relative mb-2 rounded-lg border border-gray-200 p-3 hover:bg-gray-50"
                                    >
                                        <div class="flex items-start justify-between">
                                            <div class="flex-grow">
                                                <h3
                                                    class="text-base font-semibold text-gray-500"
                                                >
                                                    {{ asset.name }}
                                                </h3>
                                                <p class="mt-1 text-xs text-gray-500">
                                                    Updated on
                                                    {{
                                                        new Date(
                                                            asset.updated_at
                                                        ).toLocaleDateString()
                                                    }}
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                @click="deleteAsset(asset.id)"
                                                class="ml-2 rounded-full p-1 hover:bg-gray-200"
                                            >
                                                <span class="sr-only">Delete Asset</span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="h-5 w-5 text-gray-500 hover:text-red-500"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M6 18 18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex justify-end">
                                    <button
                                        type="button"
                                        @click="emptyCart"
                                        class="btn btn-outline btn-error mt-6 w-32"
                                        :disabled="isDownloading"
                                    >
                                        Empty Cart
                                    </button>
                                </div>
                                <div class="flex justify-between">
                                    <button
                                        type="button"
                                        @click="downloadAll"
                                        class="btn btn-neutral mt-6 w-32"
                                        :disabled="isDownloading"
                                    >
                                        Download
                                    </button>

                                    <button
                                        type="button"
                                        @click="downloadZip"
                                        class="btn btn-outline mt-6 w-32"
                                        :disabled="isDownloading"
                                    >
                                        <span
                                            v-if="isDownloading"
                                            class="loading loading-spinner"
                                        ></span>
                                        Download ZIP
                                    </button>
                                </div>
                            </template>
                        </div>
                    </div>
                </Transition>
            </div>
        </Transition>
    </aside>
</template>
